exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calculator-automated-readability-index-js": () => import("./../../../src/pages/calculator/automated-readability-index.js" /* webpackChunkName: "component---src-pages-calculator-automated-readability-index-js" */),
  "component---src-pages-calculator-coleman-liau-index-js": () => import("./../../../src/pages/calculator/coleman-liau-index.js" /* webpackChunkName: "component---src-pages-calculator-coleman-liau-index-js" */),
  "component---src-pages-calculator-dale-chall-readability-score-js": () => import("./../../../src/pages/calculator/dale-chall-readability-score.js" /* webpackChunkName: "component---src-pages-calculator-dale-chall-readability-score-js" */),
  "component---src-pages-calculator-fernandez-huerta-js": () => import("./../../../src/pages/calculator/fernandez-huerta.js" /* webpackChunkName: "component---src-pages-calculator-fernandez-huerta-js" */),
  "component---src-pages-calculator-flesch-kincaid-grade-js": () => import("./../../../src/pages/calculator/flesch-kincaid-grade.js" /* webpackChunkName: "component---src-pages-calculator-flesch-kincaid-grade-js" */),
  "component---src-pages-calculator-flesch-reading-ease-js": () => import("./../../../src/pages/calculator/flesch-reading-ease.js" /* webpackChunkName: "component---src-pages-calculator-flesch-reading-ease-js" */),
  "component---src-pages-calculator-gunning-fog-js": () => import("./../../../src/pages/calculator/gunning-fog.js" /* webpackChunkName: "component---src-pages-calculator-gunning-fog-js" */),
  "component---src-pages-calculator-gutierrez-polini-js": () => import("./../../../src/pages/calculator/gutierrez-polini.js" /* webpackChunkName: "component---src-pages-calculator-gutierrez-polini-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-calculator-linsear-write-formula-js": () => import("./../../../src/pages/calculator/linsear-write-formula.js" /* webpackChunkName: "component---src-pages-calculator-linsear-write-formula-js" */),
  "component---src-pages-calculator-smog-index-js": () => import("./../../../src/pages/calculator/smog-index.js" /* webpackChunkName: "component---src-pages-calculator-smog-index-js" */),
  "component---src-pages-calculator-szigriszt-pazos-js": () => import("./../../../src/pages/calculator/szigriszt-pazos.js" /* webpackChunkName: "component---src-pages-calculator-szigriszt-pazos-js" */),
  "component---src-pages-calculator-text-standard-js": () => import("./../../../src/pages/calculator/text-standard.js" /* webpackChunkName: "component---src-pages-calculator-text-standard-js" */),
  "component---src-pages-calculator-wiener-sachtextformel-js": () => import("./../../../src/pages/calculator/wiener-sachtextformel.js" /* webpackChunkName: "component---src-pages-calculator-wiener-sachtextformel-js" */),
  "component---src-pages-christmas-stories-js": () => import("./../../../src/pages/christmasStories.js" /* webpackChunkName: "component---src-pages-christmas-stories-js" */),
  "component---src-pages-classic-stories-js": () => import("./../../../src/pages/classicStories.js" /* webpackChunkName: "component---src-pages-classic-stories-js" */),
  "component---src-pages-construction-stories-js": () => import("./../../../src/pages/constructionStories.js" /* webpackChunkName: "component---src-pages-construction-stories-js" */),
  "component---src-pages-educational-stories-js": () => import("./../../../src/pages/educationalStories.js" /* webpackChunkName: "component---src-pages-educational-stories-js" */),
  "component---src-pages-environment-stories-js": () => import("./../../../src/pages/environmentStories.js" /* webpackChunkName: "component---src-pages-environment-stories-js" */),
  "component---src-pages-example-story-js": () => import("./../../../src/pages/exampleStory.js" /* webpackChunkName: "component---src-pages-example-story-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-funny-stories-js": () => import("./../../../src/pages/funnyStories.js" /* webpackChunkName: "component---src-pages-funny-stories-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lora-js": () => import("./../../../src/pages/lora.js" /* webpackChunkName: "component---src-pages-lora-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-story-post-js": () => import("./../../../src/templates/story-post.js" /* webpackChunkName: "component---src-templates-story-post-js" */)
}

